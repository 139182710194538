<template>
  <div
    class="tw-flex tw-flex-col tw-h-[calc(100vh-(260px))] sm:tw-h-[calc(100vh-(200px))] md:tw-h-[calc(100vh-(125px))] xl:tw-h-screen"
    :class="mobileClass"
  >
    <b-toast
      solid
      toaster="b-toaster-top-center"
      id="cannedReplyToast"
      variant="success"
      title="Reply added!"
      no-hover-pause
      >Your reply has been succesfully added!</b-toast
    >
    <!-- header -->
    <div
      class="header-nav tw-top-0 tw-px-4 tw-py-5 tw-flex-none tw-bg-white md:tw-pl-0 md:tw-pr-12"
    >
      <div class="tw-flex tw-justify-between tw-items-center">
        <div class="tw-flex-initial tw-flex tw-items-center tw-text-warning">
          <span
            class="back-arrow tw-mr-3 tw-cursor-pointer"
            @click="$router.back()"
          ></span>
          <template v-if="isAdminView && patientInfo.firstname">
            <span class="tw-uppercase">Admin </span>
            <!-- for -->
            <!-- {{ `${patientInfo.firstname} ${patientInfo.lastname}` }} -->
          </template>
          <template v-else> Messages </template>
        </div>
        <div class="tw-pr-6 tw-hidden md:tw-block">
          <b-button
            variant="link"
            pill
            class="tw-mr-4 text-primary tw-underline"
            @click="$bvModal.show('intakehistory')"
          >
            view intake history
          </b-button>
          <b-button
            v-if="!isAdminView"
            pill
            variant="link"
            class="text-primary tw-underline tw-mr-4"
            @click="isAdminView = true"
          >
            Admin
          </b-button>
          <b-button
            v-if="isAdminView"
            pill
            variant="link"
            class="text-primary tw-underline tw-mr-4"
            @click="isAdminView = false"
          >
            Patient
          </b-button>
          <b-button
            v-if="isMarkMessagesEnabled && !isAdminView"
            pill
            variant="link"
            class="text-secondary"
            size="md"
            @click="onCancelMarkMessages"
          >
            Cancel
          </b-button>
          <b-dropdown
            v-if="!isAdminView"
            id="forward-dropdown"
            right
            split
            variant="primary"
            :split-variant="
              forwardRequestCounter === 1 ? 'primary' : 'outline-primary'
            "
            text="Forward"
            size="md"
            @click="onForwardRequest"
          >
            <b-dropdown-item @click="$bvModal.show('viewForwardMessage')">
              View Forward Requests
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="tw-flex md:tw-hidden">
          <b-button
            v-if="isMarkMessagesEnabled && !isAdminView"
            pill
            variant="link"
            class="text-danger"
            size="sm"
            @click="onCancelMarkMessages"
          >
            <b-icon font-scale="1" icon="x-circle-fill"></b-icon>
          </b-button>
          <b-dropdown
            v-if="!isAdminView"
            id="forward-dropdown"
            right
            split
            variant="primary"
            :split-variant="
              forwardRequestCounter === 1 ? 'primary' : 'outline-primary'
            "
            text="Forward"
            size="sm"
            @click="onForwardRequest"
          >
            <b-dropdown-item @click="$bvModal.show('viewForwardMessage')">
              View Forward Requests
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown
            class="tw-block xl:tw-hidden"
            variant="link"
            toggle-class="text-decoration-none"
            menu-class="custom-dropdown-menu"
            no-caret
            right
          >
            <template #button-content>
              <b-icon icon="three-dots-vertical"></b-icon>
            </template>
            <b-dropdown-item
              v-if="!isAdminView"
              class="tw-uppercase"
              link-class="tw-text-primary"
              @click="isAdminView = true"
            >
              View Admin Messages
            </b-dropdown-item>
            <b-dropdown-item
              v-else
              class="tw-uppercase"
              link-class="tw-text-primary"
              @click="isAdminView = false"
            >
              View Patient Messages
            </b-dropdown-item>
            <b-dropdown-item
              class="tw-uppercase"
              link-class="tw-text-primary"
              v-b-modal.intakehistory
            >
              Intake History
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <!-- <div v-if="isAdminView && patientInfo.firstname">
        <span class="tw-text-lg">Admin</span> for
        {{ `${patientInfo.firstname} ${patientInfo.lastname}` }}
      </div> -->
    </div>
    <template v-if="loading">
      <div
        class="tw-flex tw-items-center tw-justify-center tw-flex-grow tw-h-screen loader-animation"
      >
        Loading conversation...
      </div>
    </template>
    <div
      v-if="!loading"
      class="tw-flex tw-flex-col tw-flex-grow card-chat-body tw-h-screen tw-overflow-y-auto"
      ref="chatbox"
      infinite-wrapper
    >
      <infinite-loading
        v-if="messagesLoaded && nextPage"
        direction="top"
        @infinite="messageScroll"
        force-use-infinite-wrapper="true"
      >
        <div slot="no-more">No more message</div>
      </infinite-loading>
      <div class="tw-flex tw-flex-grow tw-flex-col-reverse">
        <template v-for="(chat, i) in messages">
          <div
            :key="'parent-' + i"
            class="tw-flex tw-items-center tw-px-6 md:tw-px-12 tw-py-2"
            :class="{
              'selected-message': selectedMessages.some(
                (msg) => msg.id === chat.id
              ),
            }"
          >
            <div
              :key="'checkbox-' + i"
              class="tw-mr-2"
              v-if="isMarkMessagesEnabled"
            >
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  :checked="isChecked(chat)"
                  class="forward-checkbox custom-control-input"
                  :id="'checkbox-' + i"
                  :name="'checkbox-' + i"
                  @change="(e) => onForwardCheckboxChange(e, chat)"
                />
                <label
                  :for="'checkbox-' + i"
                  class="custom-control-label"
                ></label>
              </div>
            </div>
            <!-- prescription_followup -->
            <div
              :key="i"
              v-if="chat.type === 'prescription_followup'"
              class="tw-flex tw-w-full tw-mb-4 tw-py-2 tw-px-4 followup-update"
            >
              <p v-if="chat.actionstatus === 'PENDING'" class="tw-mb-0">
                <span class="tw-block">
                  Follow-up questions has been sent to the patient
                </span>
                <small class="tw-block">
                  Time sent:
                  {{ moment.unix(chat.sentat).format("MMM DD, YYYY hh:mm A") }}
                </small>
              </p>
              <p v-if="chat.actionstatus === 'ANSWERED'" class="tw-mb-0">
                <span class="tw-block">
                  Follow-up questions has been answered
                </span>
                <small class="tw-block">
                  Time sent:
                  {{ moment.unix(chat.sentat).format("MMM DD, YYYY hh:mm A") }}
                </small>
                <small class="tw-block">
                  Time answered:
                  {{
                    moment
                      .unix(chat.custommessage.message.timestampanswered)
                      .format("MMM DD, YYYY hh:mm A")
                  }}
                </small>
              </p>
            </div>

            <!-- prescription_confirmation -->
            <div
              :key="i"
              v-else-if="chat.type === 'prescription_confirmation'"
              class="tw-flex tw-justify-center tw-w-full tw-mb-4 tw-py-2 tw-px-4 followup-update"
            >
              <p class="tw-font-hatton-medium">
                Updated Treatment:
                {{ chat.custommessage.message.productname }}
              </p>
              <p class="mb-0">
                The price of
                {{ chat.custommessage.message.productname }}
                treatment has changed from ${{
                  chat.custommessage.message.pricefrom
                }}
                to ${{ chat.custommessage.message.priceto }}.
              </p>
              <p v-if="chat.actionstatus === 'PENDING'" class="mb-0">
                Awaiting confirmation from patient
              </p>
              <p v-if="chat.actionstatus === 'ACCEPTED'" class="mb-0">
                Prescription has been accepted by patient
              </p>
              <p v-if="chat.actionstatus === 'DECLINED'" class="mb-0">
                Prescription has been declined by patient
              </p>
            </div>

            <div :key="i" v-else-if="chat.type === 'reassignment_transfer'">
              <b-card class="followup-update mt-1 mb-1">
                <p class="mb-0">
                  <span class="d-block mb-1">
                    Patient has been reassigned to
                    <span
                      v-if="chat.custommessage.message.doctoridto === userid"
                    >
                      you from Dr.
                      {{ chat.custommessage.message.doctorfrom }}
                    </span>
                    <span v-else>
                      Dr.
                      {{ chat.custommessage.message.doctorto }}
                    </span>
                    <span
                      v-if="
                        chat.custommessage.message.reassignmentstatus !==
                        'RETURNED'
                      "
                      >{{
                        chat.custommessage.message.assignmenttype ===
                        "TEMPORARY"
                          ? "temporarily"
                          : "permanently"
                      }}</span
                    >
                  </span>
                  <small
                    v-if="
                      chat.custommessage.message.assignmenttype ===
                        'TEMPORARY' &&
                      chat.custommessage.message.reassignmentstatus !==
                        'RETURNED'
                    "
                    class="d-block text-left time-stamp-followup"
                  >
                    {{
                      moment
                        .unix(chat.custommessage.message.startdatestamp)
                        .format("MMM DD, YYYY hh:mm A")
                    }}
                    to
                    {{
                      moment
                        .unix(chat.custommessage.message.enddatestamp)
                        .format("MMM DD, YYYY hh:mm A")
                    }}
                  </small>
                </p>
              </b-card>
            </div>

            <div
              :key="`forward-${i}`"
              v-else-if="chat.type === 'forward_message'"
              class="bubble-chat tw-flex tw-items-end"
              :class="[chat.userid != userid ? 'patient' : 'doctor']"
            >
              <div
                class="tw-flex-shrink-0 img-holder tw-size-[30px] tw-rounded-full tw-overflow-hidden tw-bg-cover bg-center"
                :style="{
                  backgroundImage: `url(${getMemberProfileUrl(chat)})`,
                }"
                style="font-size: 0"
              ></div>
              <div
                class="tw-relative tw-ml-[10px] tw-p-4 tw-max-w-lg"
                :style="
                  chat.custommessage.forwardmessage.messagestatus ===
                  'COMPLETED'
                    ? 'background-color: #F5FEEF'
                    : 'background-color: rgba(26, 106, 114, 0.05)'
                "
              >
                <div class="tw-text-right tw-mb-2">
                  <a
                    @click.prevent="
                      $refs.viewForwardMessages.onView(
                        chat.custommessage.forwardmessage.id,
                        chat.custommessage.forwardmessage.conversationid
                      )
                    "
                    class="text-secondary tw-cursor-pointer"
                  >
                    View
                  </a>
                </div>
                <div
                  v-for="forwardedMessage in chat.custommessage.forwardmessage.messagelist
                    .slice()
                    .reverse()"
                  :key="forwardedMessage.id"
                  class="tw-mb-2"
                >
                  <small class="text-muted tw-text-[11px] tw-block">
                    Sent by
                    {{
                      `${forwardedMessage.firstname} ${forwardedMessage.lastname}`
                    }}
                    at
                    {{
                      moment
                        .unix(forwardedMessage.sentat)
                        .format("MMM DD, YYYY hh:mm A")
                    }}
                  </small>
                  <div
                    class="tw-px-4 tw-py-2 tw-bg-white tw-inline-block"
                    :style="
                      forwardedMessage.usertype === 'PATIENT'
                        ? 'background-color: #FFECCA'
                        : ''
                    "
                  >
                    <div
                      v-if="
                        forwardedMessage.attachment &&
                        forwardedMessage.attachment.length > 0
                      "
                    >
                      <div
                        v-for="(
                          chatAttachment, i
                        ) in forwardedMessage.attachment"
                        :key="i"
                        style="background-color: transparent"
                      >
                        <b-row
                          no-gutters
                          v-if="chatAttachment.type != 'application/pdf'"
                        >
                          <b-col>
                            <a
                              :href="chatAttachment.url"
                              variant="link"
                              download
                              target="_blank"
                            >
                              <b-img
                                thumbnail
                                fluid
                                :src="chatAttachment.url"
                                width="250px"
                              ></b-img>
                            </a>
                          </b-col>
                        </b-row>
                        <b-row no-gutters v-else>
                          <a :href="chatAttachment.url" variant="link" download>
                            <b-icon
                              icon="arrow-down-circle-fill"
                              variant="primary"
                            ></b-icon>
                            {{ chatAttachment.filename }}
                          </a>
                        </b-row>
                      </div>
                    </div>
                    {{ forwardedMessage.message }}
                  </div>
                </div>
                <div
                  class="tw-text-center tw-mt-2 tw-text-sm tw-text-green-900"
                  v-if="
                    chat.custommessage.forwardmessage.messagestatus ===
                    'COMPLETED'
                  "
                >
                  {{ chat.custommessage.forwardmessage.messagestatus }}
                </div>
              </div>
            </div>

            <div
              v-else
              :key="i"
              class="bubble-chat tw-flex tw-items-end"
              :class="[chat.userid != userid ? 'patient' : 'doctor']"
            >
              <div
                class="tw-flex-shrink-0 img-holder tw-size-[30px] tw-rounded-full tw-overflow-hidden tw-bg-cover bg-center"
                :style="{
                  backgroundImage: `url(${getMemberProfileUrl(chat)})`,
                }"
                style="font-size: 0"
              ></div>

              <div class="chat-container tw-flex-0">
                <template v-if="chat.attachment && chat.attachment.length > 0">
                  <template v-for="(chatAttachment, i) in chat.attachment">
                    <div
                      :key="i"
                      class="shared-image tw-flex tw-flex-wrap"
                      :class="chat.message ? 'tw-mb-3' : ''"
                      v-if="
                        chatAttachment.type.includes('image') &&
                        !['tiff', 'heic', 'svg'].some((type) =>
                          chatAttachment.type.includes(type)
                        )
                      "
                    >
                      <a
                        :href="chatAttachment.url"
                        variant="link"
                        download
                        target="_blank"
                      >
                        <img
                          class="tw-object-cover tw-w-full tw-h-full"
                          :src="chatAttachment.url"
                          width="250"
                          height="250"
                          :alt="chatAttachment.filename"
                        />
                      </a>
                    </div>
                    <template v-else>
                      <div :key="i" class="p-3">
                        <a :href="chatAttachment.url" variant="link" download
                          ><b-icon
                            icon="arrow-down-circle-fill"
                            variant="primary"
                          ></b-icon>
                          {{ chatAttachment.filename }}</a
                        >
                      </div>
                    </template>
                  </template>
                </template>
                <span
                  class="messages p-3"
                  :class="isAdminView && chat.userid != userid ? 'admin' : ''"
                  v-if="chat.message"
                >
                  {{ chat.message }}
                </span>
              </div>
              <div class="timestamp tw-flex-shrink-0 tw-flex tw-flex-col">
                <small
                  v-if="isAdminView && chat.userid != userid"
                  class="tw-text-[10px] tw-text-muted"
                >
                  {{ getAdminName(chat.userid) }}
                </small>

                <small class="tw-text-[10px] tw-text-muted">
                  {{ moment.unix(chat.sentat).format("MMM DD, YYYY ") }}
                  {{ moment.unix(chat.sentat).format("hh:mm A") }}
                </small>

                <ChatRoutedTooltip :message="chat" class="tw-text-xs" />
              </div>
            </div>
          </div>
        </template>
      </div>
      <div id="bottomOfPage" ref="bottomOfPage"></div>
    </div>

    <!-- footer -->
    <!-- @submit.prevent="" -->
    <div>
      <div
        v-if="needsPatientReply && !isAdminView"
        class="tw-text-center text-secondary"
      >
        Needs patient reply
      </div>
      <div
        v-if="isAdminView && patientInfo.firstname"
        class="tw-text-center text-primary tw-mx-3 tw-py-2"
      >
        You are now messaging the admin assigned to
        {{ `${patientInfo.firstname} ${patientInfo.lastname}` }}.
      </div>
      <div
        class="tw-p-2 md:tw-px-4 tw-flex-none md:tw-sticky md:tw-bottom-0 action-btn tw-relative tw-w-full"
        v-if="!currentDoctor.id"
      >
        <div class="pre-upload" v-if="preUpload">
          <div
            class="img-holder"
            v-for="(attachment, index) in attachments"
            :key="index"
          >
            <b-icon
              icon="x-circle-fill"
              class="close-btn"
              @click="removeFile(index)"
            ></b-icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              v-if="
                !attachment.type.includes('image') ||
                attachment.type.includes('heic')
              "
            >
              <path
                fill="currentColor"
                d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8.83c0-.53-.21-1.04-.59-1.41l-4.83-4.83c-.37-.38-.88-.59-1.41-.59H6zm7 6V3.5L18.5 9H14c-.55 0-1-.45-1-1z"
              />
            </svg>
            <img v-else :src="attachment.url" :alt="attachment.name" />
          </div>
        </div>
        <div
          class="tw-grid tw-grid-cols-2 md:tw-flex md:tw-items-center tw-w-full"
          :class="{ '!tw-flex': $_orientation == 'landscape' }"
        >
          <div class="tw-col-span-2 tw-flex tw-flex-1">
            <div class="tw-flex-1">
              <b-form-textarea
                class="tw-px-0 tw-border-b-0"
                max-rows="5"
                no-resize
                ref="messageArea"
                v-model="newMessage"
                placeholder="Write a message..."
              ></b-form-textarea>
            </div>
            <div class="tw-flex-initial tw-flex tw-items-center">
              <div>
                <b-button variant="link" @click="openUpload">
                  <icon-paperclip class="tw-w-5 tw-h-5 tw-text-gray-800" />
                </b-button>
                <input
                  type="file"
                  ref="fileInput"
                  style="display: none"
                  multiple
                  v-on:change="handleFilesUpload()"
                />
              </div>
              <b-button variant="link" @click="showCannedReplyModal = true">
                <icon-canned-reply class="tw-w-5 tw-h-5 tw-text-gray-800" />
              </b-button>
            </div>
          </div>
          <div
            class="tw-col-span-2 md:tw-flex-initial tw-flex tw-items-center tw-flex-row-reverse md:tw-flex-col tw-p-2"
          >
            <b-button
              variant="primary"
              pill
              block
              @click="() => sendMessage()"
              class="tw-w-1/2 tw-h-[63px] tw-ml-3 md:tw-w-full md:tw-h-auto md:tw-ml-0 md:tw-mb-3"
              :class="isAdminView ? '!tw-w-full !tw-h-auto !tw-ml-0' : ''"
              :disabled="isMessageBodyEmpty"
            >
              <span class="tw-text-xs"> Send Message </span>
            </b-button>
            <b-button
              variant="danger"
              pill
              block
              @click="sendMessageNeedsReply"
              v-if="!isAdminView"
              class="tw-w-1/2 !tw-mt-0 md:tw-w-full"
              :disabled="isMessageBodyEmpty"
            >
              <span class="tw-text-xs"> Send Message Needs Reply </span>
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <SideModal
      :key="cannedReplyModalKey"
      :show="showCannedReplyModal"
      @exit="showCannedReplyModal = false"
      header-class="canned-reply-header"
      body-class="canned-reply-body"
      title="Canned Replies"
    >
      <template #body>
        <CannedReplyModal
          @close-canned="showCannedReplyModal = false"
          :patient-name="patientInfo.firstname"
          :is-visible="showCannedReplyModal"
          @add-attachment="addAttachment"
          @add-message="addMessage"
        />
      </template>
    </SideModal>

    <modal-forward-messages
      :selected-messages="selectedMessages"
      :conversationmemberlist="conversationmemberlist"
      :conversationid="conversationid"
      @success="onForwardMessageSuccess"
    ></modal-forward-messages>

    <modal-view-forward-messages
      ref="viewForwardMessages"
      :conversationmemberlist="conversationmemberlist"
      :conversationid="conversationid"
    ></modal-view-forward-messages>

    <portal to="needsreply">
      <template v-if="!currentDoctor.id">
        <b-button
          variant="link"
          block
          class="pb-0 px-0"
          @click="onRemoveNeedPatientReply"
          style="box-shadow: none !important"
          v-if="needsPatientReply"
        >
          <span class="text-secondary d-inline-block btn-patient-reply">
            Clear Needs Reply
          </span>
          <br />
        </b-button>
        <b-button
          variant="link"
          block
          class="pb-0 px-0"
          @click="onNeedPatientReply"
          style="box-shadow: none !important"
          v-else
        >
          <span class="text-secondary d-inline-block btn-patient-reply">
            Mark Needs Reply
          </span>
          <br />
        </b-button>
      </template>
      <div
        class="text-black text-center patient-reply-description"
        v-if="patientLastReplyDate && !currentDoctor.id"
      >
        <small>
          This patient hasn’t replied for
          <strong>
            {{ patientLastReplyDate }}
          </strong>
        </small>
      </div>
    </portal>

    <modal-intake-history :patient-info="patientInfo"></modal-intake-history>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import {
  formatDistanceToNowStrict,
  fromUnixTime,
  differenceInMinutes,
} from "date-fns";
import { v4 as uuidv4 } from "uuid";
import { OrientationMixin } from "@/mixins/orientation";

import { ChatService } from "@/services/chat.service.js";
import { PrescriptionService } from "@/services/prescription.service.js";
import audio from "@/assets/notifications/pristine.mp3";
const notificationsound = new Audio(audio);

import SideModal from "@/components/partials/SideModal";
import CannedReplyModal from "@/components/partials/CannedReplyModal";
import ModalForwardMessages from "@/components/messages/ModalForwardMessages.vue";
import ModalViewForwardMessages from "@/components/messages/ModalViewForwardMessages.vue";
import ModalIntakeHistory from "@/components/messages/ModalIntakeHistory.vue";
import ChatRoutedTooltip from "@/components/partials/ChatRoutedTooltip";

// Icons
import IconPaperclip from "@/components/icon/IconPaperclip.vue";
import IconCannedReply from "@/components/icon/IconCannedReply.vue";

export default {
  mixins: [OrientationMixin],
  components: {
    SideModal,
    CannedReplyModal,
    InfiniteLoading,
    ModalForwardMessages,
    ModalViewForwardMessages,
    ModalIntakeHistory,
    ChatRoutedTooltip,
    IconPaperclip,
    IconCannedReply,
  },
  props: {
    conversation: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      newMessage: "",
      nextPage: 1,
      loadingMessage: false,
      preUpload: false,
      conversationpatient: {
        id: "",
      },
      conversationmemberlist: [],
      conversationid: "",
      messages: [],
      attachments: [],
      files: [],
      loading: false,
      userid: "",
      showCannedReplyModal: false,
      isSending: false,

      messagesLoaded: false,

      isIos: false,
      mobileClass: "",

      isMarkMessagesEnabled: false,
      selectedMessages: [],
      selectedMessagesId: [],
      messageSelector: [],

      forwardRequestCounter: 0,

      isAdminView: false,
      patientConversationId: "",
      adminConversationId: "",

      messageReceivedFn: null,
      messageDeliveryConfirmedFn: null,
      prescriptionsWithPrices: [],

      cannedReplyModalKey: uuidv4(),
    };
  },
  mounted() {
    try {
      if (process.env.VUE_APP_MESSAGING_VERSION === "v2") {
        this.connectToSocket();
      }
      // /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
      var isIphone = /(iPhone)/i.test(navigator.platform);

      if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
        this.mobileClass = "isAndroid";
        this.isIos = false;
      }

      if (isIphone) {
        this.mobileClass = "isIos";
        this.isIos = true;
      }

      if (this.$route.query.type === "admin") {
        this.isAdminView = true;
      }
    } catch (err) {
      console.log(err);
    }
  },
  watch: {
    messages(val) {
      if (!this.messagesLoaded) {
        this.$nextTick(() => {
          this.$refs.chatbox.scrollTop = 9999;
          this.messagesLoaded = true;
        });
      }
    },
    async conversationpatient(patient) {
      if (patient) {
        await this.getDynamicPricing(this.conversationpatient.userId);
      }
    },
    isAdminView(val) {
      if (val) {
        this.listMessage();
      } else {
        this.listMessage(this.patientConversationId);
      }
    },
    loading(val) {
      if (!val) {
        this.$nextTick(() => {
          var chatBox = document.querySelector(".card-chat-body");
          chatBox.scrollTop = 9999;
        });
      }
    },
    showCannedReplyModal(val) {
      if (!val) {
        // Delay to prevent immediate closing of the side modal
        setTimeout(() => {
          this.cannedReplyModalKey = uuidv4();
        }, 500);
      }
    },
  },
  async created() {
    this.userid = localStorage.getItem("userid");
    const conversation = await this.$store.dispatch(
      "message/getSingleConversationList",
      {
        patientid: this.$route.params.id,
      }
    );
    if (conversation.length > 0) {
      this.patientConversationId = conversation[0].id;
      await this.listMessage(conversation[0].id);
    }
  },
  computed: {
    currentDoctor() {
      return this.$store.state.globals.currentDoctor;
    },
    patientLastReplyDate() {
      const findPatientLastReply = this.messages.find(
        (message) => message.userid === this.conversationpatient.userId
      );
      if (findPatientLastReply) {
        const lastPatientReplyDate = fromUnixTime(findPatientLastReply.sentat);
        const minuteTimeDifference = differenceInMinutes(
          new Date(),
          lastPatientReplyDate
        );
        if (minuteTimeDifference >= 3) {
          return formatDistanceToNowStrict(lastPatientReplyDate);
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    needsPatientReply() {
      const patient = this.conversationmemberlist.find(
        (member) => member.type === "PATIENT"
      );
      if (patient) {
        return patient.needsPatientReply;
      } else {
        return false;
      }
    },
    ...mapGetters({
      patientInfo: "patients/getPatientInfo",
      socket: "socket/getSocket",
    }),
    isMessageBodyEmpty() {
      if (this.newMessage) {
        return this.newMessage.trim() === "";
      } else {
        // Check if there's a file included during sending
        return !(this.files.length > 0);
      }
    },
  },
  methods: {
    addAttachment(attachment) {
      this.attachments.push(attachment);
      this.files.push(attachment);
    },
    async getDynamicPricing(patientid) {
      const { data } = await PrescriptionService.getProductDosageAndUnit(
        patientid
      );
      this.prescriptionsWithPrices = data;
    },
    addMessage(content) {
      let tags = content.match(/\{(.*?)\}/g);
      if (!tags) {
        tags = [];
      }

      // More static properties
      // Preload into array
      let splitDOB = this.conversationpatient.id
        ? this.conversationpatient.birthday.split("-")
        : this.patientInfo.birthday.split("-");

      let dob = `${splitDOB[1]}/${splitDOB[2]}/${splitDOB[0]}`;

      let tagsToReplace = [
        { tag: "{doctor}", value: this.$store.state.user.currentUser.lastname },
        {
          tag: "{patient}",
          value:
            this.conversationpatient.firstname || this.patientInfo.firstname,
        },
        { tag: "{DOB}", value: dob },
        {
          tag: "{First Name}",
          value:
            this.conversationpatient.firstname || this.patientInfo.firstname,
        },
        {
          tag: "{Last Name}",
          value: this.conversationpatient.lastname || this.patientInfo.lastname,
        },
        {
          tag: "{Name}",
          value: `${
            this.conversationpatient.firstname || this.patientInfo.firstname
          } ${this.conversationpatient.lastname || this.patientInfo.lastname}`,
        },
        {
          tag: "{State}",
          value: this.conversationpatient.state || this.patientInfo.state,
        },
      ];
      for (let tag of tags) {
        let splitted = tag
          .replace(/Price - /g, "")
          .replace(/[{}]/g, "")
          .split("/");

        // If tag is not name/interval/quantity, assume static and continue loop
        if (splitted.length == 1) {
          continue;
        }

        if (splitted[1] == "onetime") {
          splitted[1] = 0;
        }
        // Find prescription
        let prescription = this.prescriptionsWithPrices.filter(
          (o) => o.name == splitted[0]
        )[0];

        if (!prescription) {
          this.$bvToast.toast(
            "Cannot find prescription that matches the tag. Tag will not be translated",
            {
              variant: "warning",
              noCloseButton: true,
              autoHideDelay: 2000,
              toaster: "b-toaster-bottom-center",
            }
          );
          continue;
        }

        // Generate list of all details to search from
        let listOfDetails = [];
        for (let detail of prescription.details) {
          listOfDetails.push(...detail.detail);
        }

        // Search for correct pricing
        let price = listOfDetails.find(
          (o) =>
            Number(o.interval) == Number(splitted[1]) &&
            Number(o.quantity) == Number(splitted[2])
        );

        if (!price) {
          this.$bvToast.toast(
            "Cannot find price that matches the tag. Tag will not be translated",
            {
              variant: "warning",
              noCloseButton: true,
              autoHideDelay: 2000,
              toaster: "b-toaster-bottom-center",
            }
          );
          continue;
        }

        tagsToReplace.push({ tag: tag, value: `$${price.price}` });
      }

      for (let tag of tagsToReplace) {
        // Add escaping of parentheses for progesterone
        let tagEscaped = tag.tag.replace("(", "\\(").replace(")", "\\)");
        let regex = new RegExp(tagEscaped, "g");
        content = content.replace(regex, `${tag.value}`);
      }

      if (!this.newMessage) {
        this.newMessage = content;
        this.newMessage = this.newMessage.concat("\n");
      } else {
        // Get cursor position
        const cursor = this.$refs.messageArea.selectionStart;
        let cursorPos = cursor;
        if (cursor || (cursor && this.newMessage.length)) {
          this.newMessage = [
            this.newMessage.slice(0, cursor),
            content.concat("\n"),
            this.newMessage.slice(cursor),
          ].join("");

          setTimeout(() => {
            this.$refs.messageArea.focus();
            cursorPos += content.length + 1;
            this.$refs.messageArea.selectionStart =
              this.$refs.messageArea.selectionEnd = cursorPos;
          }, 10);
        } else {
          this.newMessage = this.newMessage.concat(content).concat("\n");
        }
      }

      this.$bvToast.show("cannedReplyToast");
    },
    openUpload() {
      this.$refs.fileInput.click();
    },
    handleFilesUpload() {
      let uploadedFiles = this.$refs.fileInput.files;
      this.preUpload = true;
      const maxFileSize = 7 * 1024 * 1024; //7mb
      for (var i = 0; i < uploadedFiles.length; i++) {
        if (uploadedFiles[i] && uploadedFiles[i].size > maxFileSize) {
          this.$bvToast.toast("Maximum file size is 7mb", {
            variant: "warning",
            noCloseButton: true,
            autoHideDelay: 2000,
            toaster: "b-toaster-bottom-center",
          });
        } else {
          this.files.push(uploadedFiles[i]);
          uploadedFiles[i].url = URL.createObjectURL(uploadedFiles[i]);
          this.attachments.push(uploadedFiles[i]);
        }
      }
    },
    removeFile(key) {
      this.attachments.splice(key, 1);
      if (this.attachments.length == 0) {
        this.attachments = [];
        this.files = [];
        this.preUpload = false;
      }
    },
    async sendMessage(needsreply) {
      try {
        if (this.isMessageBodyEmpty) {
          if (!(this.files.length > 0)) {
            return;
          }
        }
        if (this.isSending === false) {
          this.isSending = true;

          let obj = {
            id: uuidv4(),
            conversationId: this.conversationid,
            sentAt: Math.floor(Date.now() / 1000),
            message: this.newMessage,
            needsReply: needsreply ? true : false,
          };
          if (this.files.length > 0) {
            obj.attachment = Object.keys(this.files).map((key) => ({
              name: this.files[key].name,
              size: this.files[key].size,
              type: this.files[key].type,
              file: this.files[key],
            }));
            this.socket.emit("new_message", obj);
            this.attachments = [];
            this.files = [];
          } else {
            this.socket.emit("new_message", obj);
          }

          this.newMessage = "";
          this.preUpload = false;
          var chatBox = document.querySelector(".card-chat-body");
          chatBox.scrollTop = 9999;
          this.scrollToBottom();
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error.message);
        return false;
      } finally {
        this.isSending = false;
        this.newMessage = "";
        this.attachments = [];
        this.files = [];
        this.preUpload = false;
      }
    },
    async sendMessageNeedsReply() {
      await this.sendMessage(true);
      await this.$store.dispatch(
        "message/needPatientReply",
        this.conversationid
      );
      this.listConversationMember();
    },
    async onNeedPatientReply() {
      await this.$store.dispatch(
        "message/needPatientReply",
        this.conversationid
      );
      this.listConversationMember();
    },
    async onRemoveNeedPatientReply() {
      await this.$store.dispatch(
        "message/removeNeedPatientReply",
        this.conversationid
      );
      this.listConversationMember();
    },
    listConversationMember() {
      ChatService.listConversationMember(this.conversationid).then(
        ({ data: members }) => {
          this.conversationmemberlist = members;
        }
      );
    },
    async listMessage(conversationid) {
      try {
        if (this.isAdminView) {
          if (this.adminConversationId) {
            this.conversationid = this.adminConversationId;
          } else {
            const { data } = await ChatService.getAdminConversationDetails(
              this.$route.params.id
            );
            this.adminConversationId = data.id;
            this.conversationid = data.id;
          }
        } else {
          this.conversationid = conversationid;
        }
        this.newMessage = "";
        this.nextPage = null;
        this.loading = true;
        this.loadingMessage = false;

        ChatService.listMessage(this.conversationid, this.nextPage).then(
          ({ data: messages }) => {
            this.messages = messages.items;
            this.nextPage = messages.nextpage;
            this.loading = false;
          }
        );

        ChatService.listConversationMember(this.conversationid).then(
          ({ data: members }) => {
            // listConversationMember
            this.conversationmemberlist = members;
            for (let member of members) {
              if (member.type === "PATIENT") {
                this.conversationpatient = member;
              }
            }
          }
        );
      } catch (error) {
        console.log(error.message);
        this.loading = false;
      }
    },
    getMemberProfileUrl({ userid, profileurl }) {
      return profileurl || require("@/assets/images/anon.jpeg");
    },
    async chatMessage($state) {
      const { data: messages } = await ChatService.listMessage(
        this.conversationid,
        this.nextPage
      );
      for (let i = 0; i < messages.items.length; i++) {
        let message = messages.items[i];
        this.messages.push(message);
      }
      if (messages.nextpage) {
        this.nextPage = messages.nextpage;
        $state.loaded();
      } else {
        this.nextPage = null;
        $state.complete();
      }
    },
    async scrollToBottom() {
      try {
        await this.$nextTick();
        this.$refs.bottomOfPage.scrollIntoView();
      } catch (error) {
        console.log(error.message);
      }
    },
    messageScroll($state) {
      try {
        if (this.nextPage && !this.loadingMessage) {
          this.loadingMessage = true;
          this.chatMessage($state);
          this.loadingMessage = false;
        }
      } catch {
      } finally {
        this.loadingMessage = false;
      }
    },
    isChecked(message) {
      return this.selectedMessages.some((msg) => msg.id === message.id);
    },
    onForwardCheckboxChange(e, message) {
      if (!e.target.checked) {
        e.target.checked = true;

        if (
          message.id === this.messageSelector?.[0] &&
          this.messageSelector.length === 1
        ) {
          this.selectedMessages = [];
          this.messageSelector = [];
          return;
        }

        if (
          message.id === this.messageSelector?.[0] &&
          this.messageSelector.length === 2
        ) {
          this.selectedMessages = [message];
          this.messageSelector.splice(1, 1);
          return;
        }

        const removeIndex = this.selectedMessages.findIndex(
          (msg) => msg.id === message.id
        );
        this.selectedMessages.splice(
          this.messageSelector.includes(message.id)
            ? removeIndex
            : removeIndex + 1,
          this.selectedMessages.length
        );

        if (this.selectedMessages.length > 1) {
          this.messageSelector[1] =
            this.selectedMessages[this.selectedMessages.length - 1].id;
        } else {
          this.messageSelector.splice(1, 1);
        }

        return;
      }

      this.selectedMessages = [];
      if (this.messageSelector.length <= 1) {
        if (message.id !== this.messageSelector?.[0]) {
          this.messageSelector.push(message.id);
        }
      } else if (this.messageSelector.length === 2) {
        this.messageSelector[1] = message.id;
      }

      if (this.messageSelector.length === 1) {
        this.selectedMessages.push(message);
      } else if (this.messageSelector.length === 2) {
        let count = 0;
        this.selectedMessages = [];

        const firstMessageIndex = this.messages.findIndex(
          (el) => el.id === this.messageSelector[0]
        );
        const secondMessageIndex = this.messages.findIndex(
          (el) => el.id === this.messageSelector[1]
        );

        let clonedMessages;
        if (firstMessageIndex > secondMessageIndex) {
          clonedMessages = [...this.messages].reverse();
        } else if (firstMessageIndex < secondMessageIndex) {
          clonedMessages = [...this.messages];
        }

        for (const currentMessage of clonedMessages) {
          if (count === 2) {
            break;
          }
          if (this.messageSelector.includes(currentMessage.id)) {
            count += 1;
          }

          if (count === 1 || count === 2) {
            this.selectedMessages.push(currentMessage);
          }
        }
      }
    },
    onCancelMarkMessages() {
      this.isMarkMessagesEnabled = false;
      this.selectedMessages = [];
      this.messageSelector = [];
      this.forwardRequestCounter = 0;
    },
    onForwardMessageSuccess() {
      this.isMarkMessagesEnabled = false;
      this.selectedMessages = [];
      this.messageSelector = [];
      this.forwardRequestCounter = 0;
    },
    onForwardRequest() {
      if (this.currentDoctor.id) {
        return;
      }

      if (this.forwardRequestCounter === 0) {
        this.forwardRequestCounter += 1;
        this.isMarkMessagesEnabled = true;
      } else {
        if (this.selectedMessages.length > 0) {
          this.$bvModal.show("forwardMessage");
        } else {
          this.$bvToast.toast("Kindly mark your messages first.", {
            title: "Something went wrong",
            variant: "warning",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
        }
      }
    },
    getAdminName(userid) {
      const selectedAdmin = this.conversationmemberlist.find(
        (member) => member.userId === userid
      );
      return selectedAdmin
        ? `${selectedAdmin.firstName} ${selectedAdmin.lastName} (Admin)`
        : "";
    },
    connectToSocket() {
      if (this.socket) {
        this.messageDeliveryConfirmedFn = (message) => {
          if (this.conversationid === message.conversationid) {
            this.onMessageReceived(message);
          }
        };
        this.socket.on(
          "message_delivery_confirmed",
          this.messageDeliveryConfirmedFn
        );

        this.messageReceivedFn = (message) => {
          if (this.conversationid === message.conversationid) {
            this.onMessageReceived(message);
            console.log("Received message", message);
          }
        };
        this.socket.on("message", this.messageReceivedFn);
      }
    },
    onMessageReceived(message) {
      if (message.action === "SENT") {
        var newmessage = message;
        if (newmessage.userid !== this.userid) {
          notificationsound.play();
          this.$store.dispatch("message/markAsRead", {
            conversationid: newmessage.conversationid,
            messageid: newmessage.id,
          });
          if (this.needsPatientReply) {
            this.listConversationMember();
          }
        }
        if (newmessage.type === "prescription_confirmation") {
          if (newmessage.custommessage.constructor.name === "String") {
            newmessage.custommessage = JSON.parse(newmessage.custommessage);
          }
        }
        this.messages.splice(0, 0, newmessage);

        this.loading = false;
        return this.messages;
      } else if (message.action === "UPDATED") {
        var messageupdate = message;
        let messagetoupdate = this.messages.find(
          (x) => x.id === messageupdate.id
        );
        if (messagetoupdate != undefined) {
          if (messagetoupdate.type === "prescription_confirmation") {
            messagetoupdate.actionstatus = messageupdate.actionstatus;
            notificationsound.play();
          } else if (messagetoupdate.type === "prescription_followup") {
            messagetoupdate.custommessage = JSON.parse(
              messageupdate.custommessage
            );
            messagetoupdate.actionstatus = messageupdate.actionstatus;
            notificationsound.play();
          }
        }
      }
    },
  },
  destroyed() {
    if (this.messageReceivedFn) {
      this.socket.off("message", this.messageReceivedFn);
      this.socket.off(
        "message_delivery_confirmed",
        this.messageDeliveryConfirmedFn
      );
    }
  },
};
</script>

<style scoped>
/deep/#forward-dropdown .dropdown-menu .dropdown-item {
  color: #1a6a72;
  padding: 0.875rem;
}

/deep/#forward-dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #ffecca;
}

/deep/#forward-dropdown .dropdown-menu {
  border-top: 3px solid #1a6a72;
  padding: 0;
}

.forward-checkbox.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #ff7a37 !important;
  background-color: #ff7a37 !important;
}
</style>

<style lang="scss" scoped>
.isIos {
  height: calc(100vh - (125px + (75px + 120px)));
}

.followup-update {
  background-color: #e9f5f3;
}

#cannedReplyToast__toast_outer {
  z-index: 999 !important;
  margin-top: 40vh;
  margin-left: 5vw;
}

.close-btn {
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 2;
  background-color: $white;
  border-radius: 100%;
}

.pre-upload {
  border-top: 1px solid rgba($primary, 0.015);
  display: flex;
  overflow: hidden;
  overflow-x: auto;
  padding-top: 5px;
  padding-left: 10px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  // background-color: $white;
  transition: background-color ease-in-out 0.5s, border-color ease-in-out 0.5s;

  .img-holder {
    position: relative;
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border: 1px solid $border-color;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
.bubble-chat {
  width: 100%;
  margin-bottom: 1rem;

  .messages {
    display: inline-block;
    word-break: break-word;
  }

  &.patient {
    .img-holder {
      margin-right: 8px;
    }

    .timestamp {
      margin-left: 8px;
    }

    .shared-image {
      justify-content: start;
    }
  }

  &.doctor {
    flex-direction: row-reverse;
    justify-content: end;

    .img-holder {
      margin-left: 8px;
    }

    .timestamp {
      margin-right: 8px;
      text-align: right;
    }

    .shared-image {
      justify-content: end;
    }
  }
}

.bubble-chat.patient .messages {
  background-color: #ffecca;
}

.darkmode .bubble-chat.patient .messages {
  background-color: rgba(255, 236, 202, 0.1);
}

.darkmode .bubble-chat.patient .messages.admin {
  background-color: #143b30 !important;
}

.bubble-chat.doctor .messages {
  background-color: rgba(26, 106, 114, 0.025);
}

.chat-container {
  // width: 70%;
  max-width: 70%;
  overflow: hidden;
  white-space: pre-line;

  @include media-breakpoint-up(md) {
    // width: 50%;
    max-width: 50%;
  }
}

.action-btn {
  border-top: 1px solid #e0e0e0;
}

.header-nav,
.action-btn {
  background-color: #fff;
}

.darkmode .header-nav,
.darkmode .action-btn {
  background-color: #000; // #121212

  @include media-breakpoint-up(md) {
    background-color: #121212; // #121212
  }
}

.selected-message {
  background: rgba(255, 236, 202, 0.33);
}

.btn-patient-reply {
  border-bottom: 1px solid #ff7a37;
  padding-bottom: 0.125rem;
}
</style>
